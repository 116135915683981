export const indexReducer = (state: any, action: any) => {
  switch (action.type) {
    case 'SET_LAYOUT_USERS_ORDER':
      return {
        ...state,
        ...action.data,
      }
    default:
      return state
  }
}

export const configReducer = (state: any, action: any) => {
  switch (action.type) {
    case 'LOGIN':
      return {
        ...state,
        isLoggedIn: true,
        isLoggedOut: false,
      }

    case 'LOGOUT':
      return {
        ...state,
        isLoggedIn: false,
        isLoggedOut: true,
      }

    default:
      return state
  }
}

export const userReducer = (state: any, action: any) => {
  switch (action.type) {
    case 'ADD_USER':
      return {
        ...state,
        ...action.data,
      }
    default:
      return state
  }
}
