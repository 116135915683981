import { gql, useLazyQuery } from '@apollo/client'
// import { useContext } from 'react'
// import { AppContext } from '../../store/context'

const GET_USER = gql`
  query GetEmployeeProfile {
    getEmployeeProfile {
      message
      status
      statusCode
      error
      data {
        employeeId
        empRfidNo
        companyId
        companyName
        employeeSalutation
        firstName
        familyName
        addressFirstLine
        addressSecondLine
        houseNumber
        town
        postCode
        canton
        userName
        email
        mobile
        dailCode
        feelEatPhoneNumber
        shellCallNumber
        active
        country
        mobileVerificationStatus
        emailVerificationStatus
        accessRights
        locationId
        cardNumber
        cardActive
        cardIssueDate
        recieveMail
        dob
        joiningDate
        relievingDate
        weeklyWorkingHours
        isCorporateAccount
        allowCreditBalance
        lastBonusTransferedFor
        department
        test
        dontSendReceiptEmail
        sendFridgeLoginAlert
        dontSendPaymentChaserAlert
        lastPaymentChaserAlert
        lastPaymentChaserMailSendDate
        colorCode
        verifyEmailDomain
        nfcBadgeSerialNumber
        isDeleted
        isPreferencesEnable
        isAllergiesEnable
        enableFavouriteProductNotification
        skipLogin2FA
        isDummyAccount
        creationDate
      }
    }
  }
`

const useGetCurrentUserProfile = () => {
  // const { dispatch } = useContext(AppContext)

  const [getUserProfile, { error, loading, data }] = useLazyQuery(GET_USER)

  console.log('useGetCurrentUserProfile===>', { error, loading, data, x: data?.getEmployeeProfile?.data })
  /** 
   * if we don't want to show error notification in case of failure then pass disableErrorNotification as false
  const [getUserProfile, { error, loading, data }] = useLazyQuery(GET_USER, {
    context: context({ disableErrorNotification: false }),
  })
  */

  // if (data?.getEmployeeProfile) {
  //   dispatch({
  //     type: 'ADD_USER',
  //     data: data?.employeeSignIn?.data,
  //   })
  // }

  return {
    getUserProfile,
    error,
    data: data === null ? null : data?.getEmployeeProfile?.data,
    loading,
  }
}

export default useGetCurrentUserProfile
